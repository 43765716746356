<template lang="pug">
  tr.app-table__row
    td.app-table__cell.day-week-name-cell(@click="gotToCourse")
      p.course-date
        span {{ item.date | DOWDutchDDD }}
        span {{ item.date }}
    td.app-table__cell.course-name
      course-name-edit(
        ref="courseNameEdit"
        :courseName="item.name"
        @save="saveCourseName"
      )
    td.app-table__cell.location-cell
      span.location-name(@click="gotToCourse") {{ location }}
      div.location-toggle(@click="onSwitcherClick")
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            v-switch(
              :input-value="item.isVisibleOnLanding"
              readonly
              v-on="on"
              hide-details
            ).my-0.py-0
          span Visible on landing

      .counts-column
        .counts-column__wrapper
          course-counts(
            withTooltip
            :item="item"
            @updated="$emit('updateCounts', $event)"
          )
    td.app-table__cell.exam-location-cell
      div.exam.text-no-wrap(v-for="exam in item.exams")
        p {{ exam.location }}
    td.app-table__cell.exam-date-cell
      div.exam.text-no-wrap(v-for="exam in item.exams")
        p.course-date
          span(v-if="exam.formatted_date_from && exam.formatted_date_to")
            span {{ exam.formatted_date_from | DOWDutchDDD }} {{ exam.formatted_date_from }} -
            br
            span {{ exam.formatted_date_to | DOWDutchDDD }} {{ exam.formatted_date_to }}
          span(v-else)
            span {{ exam.exam_date_future_days }} days

    td.app-table__cell.exam-time-cell
      div.exam.text-no-wrap(v-for="exam in item.exams")
        p {{ exam.formatted_timeFrom }} - {{ exam.formatted_timeTo }}

    td.app-table__cell
      .exam-wrapper
        div.exam-counts-wrapper
          div.exam.exam-counts(v-for="exam in item.exams")
            examCounts(:item="exam" withTooltip)


        notes.notes(
          :value="item.notes"
          :service="this.svc"
          :buttonPlaceholder="'Notes'"
          :textPlaceholder="'Course notes...'"
          :ID="item.ID"
          @saved="$emit('updateNotes', { ID: item.ID, notes: $event })"
        )
    td.app-table__cell
      div.actions.d-flex.align-center
        course-remove(@onSuccess="$emit('updateList')" :ID="item.ID")
    confirmation-dialog(
      v-model="showConfirm"
      @okBtnClicked="changeVisibleOnLanding"
      width="410px"
      failText='Cancel'
      okText='Ok'
      descr="Are you sure you want to change course visibility?"
      title='Course visibility will be changed'
    )
</template>


<script>
import { mapGetters } from 'vuex';
import errorsMixin from '@/mixins/errors.mixin';
import { CourseModelDetailed } from "../../../models/courseModel";
import featureFlagsMixin from '@/mixins/featureFlags.mixin';

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
  },
  inject: ['svc'],
  mixins: [ errorsMixin, featureFlagsMixin ],
  data() {
    return {
      loading: false,
      removeModalShowing: false,
      cachedCourse: {},
      showConfirm: false
    }
  },
  computed: {
    ...mapGetters({
      crmCourseLocation: 'crmCourseCities/items'
    }),
    location() {
      if (this.item.city && this.crmCourseLocation.map(loc => +loc.ID).includes(+this.item.city)) {
        return this.crmCourseLocation.find(loc => loc.ID === this.item.city).name;
      }
      return ''
    }
  },
  mounted() {
    this.cacheCourse(this.item)
  },
  watch: {
    item(newValue) {
      this.cacheCourse(newValue)
    }
  },
  methods: {
    async saveCourseName(newName) {
      try {
        await this.changeField('name', 'name', newName);
        this.item.name = newName
        this.$refs.courseNameEdit.hideModal()
      } catch(err) {
        return
      }
    },
    onNameChanged(e) {
      this.item.name = e.target.innerHTML
      this.changeField('name', 'name');
    },
    async changeVisibleOnLanding() {
      this.item.isVisibleOnLanding = !this.item.isVisibleOnLanding;
      this.changeField('is_visible_on_landing', 'isVisibleOnLanding')
    },
    onSwitcherClick() {
      this.showConfirm = true;
    },
    gotToCourse() {
      this.$router.push({
        name: this.$ROUTER_NAMES.COURSE_MANAGEMENT,
        query: {
          city: this.item.city,
          page: 1,
          size: 50,
          course_id: [this.item.ID],
          page_name: 'all_reserved'
        }
      })
    },
    handleKeydown(event, field, name) {
      if (event.keyCode === 13) this.changeField(field, name);
    },
    preventNegative(event, field, name) {
      if (event.key === '-' || event.key === '.') event.preventDefault();
      if (event.keyCode === 13) this.changeField(field, name);
    },
    async changeField(field, name, inputValue) {
      let value = inputValue ? inputValue : this.item[name];
      if(typeof inputValue === 'boolean') value = inputValue
      if(this.loading || this.cachedCourse[name] === value) return;
      const data = {};
      data[field] = value;
      try {
        this.loading = true;
        const updatedCourse = await this.svc.updateCourse(this.item.ID, data);
        this.cacheCourse(new CourseModelDetailed(updatedCourse));
        this.$notify({text: 'Course updated', type: 'success'});
        this.loading = false;
        return Promise.resolve()
      } catch ({invalid_fields}) {
        if (invalid_fields && invalid_fields[field]) {
          this.processError({message: invalid_fields[field][0]});
        }
        this.item[name] = this.cachedCourse[name];
        this.loading = false;
        return Promise.reject()
      }
    },
    cacheCourse(item) {
      this.cachedCourse = {...item};
    }
  },
  components: {
    confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue'),
    groupedListItem: () => import('@/components/global/grouped-list/GroupedListItem.vue'),
    courseCounts: () => import('./СMCourseCounts.vue'),
    examCounts: () => import('./cm_exam/CMExamCounts.vue'),
    courseRemove: () => import('./CMCourseRemove.vue'),
    notes: () => import('@/app/admin/modules/candidates/components/candidates/CandidatesNote.vue'),
    courseNameEdit: () => import('./CourseNameEdit.vue'),
  }
}
</script>

<style lang="scss" scoped>

  .app-table__cell{
    font-weight: 500;

    &.exam-location-cell{
      min-width: 300px;
      max-width: 300px;

      p{
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &.day-week-name-cell{
      min-width: 168px;
    }

    &.location-cell{
      min-width: 420px;
    }

    &.counts-notes-cell{
      min-width: 220px;
    }

    &.exam-date-cell{
      min-width: 130px;
    }

    &.exam-time-cell{
      min-width: 115px;
    }
  }

  .app-table__row{
    cursor: pointer;
    position: relative;
    vertical-align: middle;
  }

  .location-toggle {
    width: 50px;
    display: inline-block;
    vertical-align: middle;

    &::v-deep {
      .v-input__slot {
        margin-bottom: 0;
      }
    }
  }



  .location-name{
    display: inline-block;
    width: 85px;
    overflow: hidden;
    margin: 0;
    vertical-align: middle;
  }

  .exam-wrapper{
    display: flex;
    align-items: center;
  }

  .exam-counts-wrapper{
    min-width: 150px;
  }

  .exam {
    min-width: 80px;
    &-date {
      span {
        display: inline-block;

        &:nth-child(1) {
          margin-right: 4px;
        }
      }
    }

    &.exam-counts{
      min-width: 150px;
      margin: 10px 0;

      ::v-deep .counts{
        border:1px solid $border-color;

        &__item_slots{
          border-right: 1px solid $warning-color;
        }
      }

      &:last-of-type{
        ::v-deep .counts{
          border-bottom: 1px solid $border-color;
        }
      }
    }

    p {
      margin: 10px 0;
      display: inline-block;
    }
  }

  .course-name {
    min-width: 160px;

    &__input {
      @extend %visible-scrollbar;

      max-width: 140px;
      border: none;
      outline: none;
      -webkit-appearance: none;
      white-space: nowrap;
      overflow: auto;

      &.editable{
        pointer-events: inherit;
      }

      &[disabled] {
        opacity: 0.7;
      }
      &:focus {
        &::placeholder {
          color: transparent;
        }
      }
    }
  }

  .notes{
    display: inline-block;
    vertical-align: middle;
    margin-left: 16px;
    width: 80px;
  }

  .counts-column{
    min-width: 250px;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;

    &__wrapper {
      width: 200px;
      display: inline-block;
    }

    &__places {
      flex: 1;
      align-items: center;
      padding: 5px 0;
      width: 50px;
      text-align: center;
      color: $label-color;
      border: 1px solid $border-color;
      border-right: 0;
      outline: none;
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    ::v-deep {
      .counts {
        display: flex;
        align-items: center;
        border: 1px solid $border-color;
        border-right: none;

        &__item:last-child{
          border-right: 1px solid $warning-color;
        }
      }
    }
  }

  .course-date {
    margin: 0;
    display: inline-block;
    span {
      display: inline-block;

      &:nth-child(1) {
        margin-right: 4px;
      }
    }
  }



</style>
